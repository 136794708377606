<template>
    <div class="apply-detail-container">
        <div class="apply-detail-container-top">
            <van-nav-bar title="申领单详情" fixed/>
        </div>
        <div class="apply-detail-container-content">
            <van-cell-group>
                <van-cell title="申领单号" :value="order.master.id"/>
                <van-cell title="申领时间" :value="order.master.createTime"/>
                <van-cell title="申领部门" :value="order.master.deptName"/>
                <van-cell title="申领人" :value="order.master.userName"/>
                <van-cell title="申领状态" :value="order.master.statusName"/>
                <van-cell title="回款状态" :value="order.master.returnStatusName"/>
                <van-cell title="开票状态" :value="order.master.invoiceStatusName"/>
                <van-cell title="申领类型" :value="order.master.type"/>
                <van-cell title="申领客户" :value="order.master.customer"/>
                <van-cell title="联系人" :value="order.master.connect"/>
                <van-cell title="联系方式" :value="order.master.mobile"/>
                <van-cell title="回款方式" :value="order.master.paymentName"/>
                <van-cell title="回款期限" :value="order.master.collectionDays + '天'"/>
                <van-cell title="申领明细" v-if="order.goods.length > 0" is-link :arrow-direction="showGoods?'down':''"
                          @click="showGoods=!showGoods"/>
                <div class="apply-goods" v-show="showGoods">
                    <van-row class="apply-goods-title">
                        <van-col span="4">类型</van-col>
                        <van-col span="4">规格</van-col>
                        <van-col span="4">面值</van-col>
                        <van-col span="4">数量</van-col>
                        <van-col span="4">折扣</van-col>
                        <van-col span="4">金额</van-col>
                    </van-row>
                    <van-row class="apply-goods-item" v-for="(goods, index) in order.goods" v-bind:key="index">
                        <van-col span="4">{{goods.type}}</van-col>
                        <van-col span="4">{{goods.spec}}</van-col>
                        <van-col span="4">{{goods.face}}</van-col>
                        <van-col span="4">{{goods.num}}</van-col>
                        <van-col span="4">{{goods.discount}}</van-col>
                        <van-col span="4">{{goods.amount}}</van-col>
                    </van-row>
                </div>
                <van-cell title="申领备注" :value="order.master.remark"/>
                <div class="apply-detail-container-btn">
                    <!-- 每个元素的两侧间隔相等 -->
                    <van-row type="flex" justify="space-around">
                        <van-col span="8" v-if="order.master.status > 1 && auth.indexOf('/sale/apply/rollback') >= 0">
                            <van-button square type="warning" icon="my iconfont icon-fanshen" size="small" text="订单反审"
                                        @click="onRollback"/>
                        </van-col>
                        <van-col span="8" v-if="order.master.audit === 1 && order.master.status === 1 && order.master.vipCard !== 1 && order.master.collectionDays < 180 && auth.indexOf('/sale/apply/audit') >= 0">
                            <van-button square type="info" icon="success" size="small" text="订单审核"
                                        @click="onAudit"/>
                        </van-col>
						<van-col span="8" v-if="order.master.audit === 1 && order.master.status === 1 && order.master.vipCard !== 1 && order.master.collectionDays >= 180 && auth.indexOf('/sale/apply/auditsenior') >= 0">
						    <van-button square type="info" icon="success" size="small" text="订单审核"
						                @click="onAudit"/>
						</van-col>
						<van-col span="8" v-if="order.master.audit === 1 && order.master.status === 1 && order.master.vipCard === 1">
						    <van-button square type="info" icon="success" size="small" text="订单审核"
						                @click="onAudit"/>
						</van-col>
                        <van-col span="8" v-if="order.master.designee === 0 && order.master.status === 1">
                            <van-button square type="primary" icon="edit" size="small" text="修改订单" @click="onEdit"/>
                        </van-col>
                        <van-col span="8" v-if="order.master.designee === 0 && order.master.status === 1">
                            <van-button square type="danger" icon="delete" size="small" text="删除订单"
                                        @click="onDelete"/>
                        </van-col>
                    </van-row>
                </div>
            </van-cell-group>
        </div>
        <!-- 弹出区域 -->
        <div class="apply-detail-container-popup">
            <!-- 反审 -->
            <van-popup v-model="picker.rollback" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="反审状态"
                        :columns="rollbackStatus"
                        @confirm="onRollbackComplete"
                        @cancel="picker.rollback=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 修改申领单 -->
            <van-popup v-model="picker.edit" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <edit :param.sync="order.master" :key="order.master.id" @onClose="onEditComplete"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import edit from './input'

    export default {
        name: 'apply-detail',
        components: {
            edit
        },
        props: ['param'],
        data() {
            return {
                auth: this.$store.state.user.auth || [],
                order: {
                    master: {},
                    goods: []
                },
                showGoods: true,
                picker: {
                    edit: false,
                    rollback: false
                },
                rollbackStatus: [
                    {index: 1, text: '申请中'},
                    {index: 2, text: '已审核'}
                ]
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            // 初始化
            init() {
                let that = this;
                if (that.param && that.param.id > 0) {
                    that.$api.apply.detail(that.param.id).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.order = res.data;
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }
            },
            // 审核订单
            onAudit() {
                let that = this;
                that.$dialog.confirm({
                    message: '确认审核吗？'
                }).then(() => {
                    that.$api.apply.audit(that.order.master).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.order.master.status = 2;
                            that.order.master.statusName = '已审核';
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                });
            },
            // 订单反审
            onRollback() {
                this.picker.rollback = true;
            },
            // 反审完成
            onRollbackComplete(value) {
                let that = this;
                if (value.index == that.order.master.status)
                    return that.$toast('状态未变更');
                let info = {
                    id: that.order.master.id,
                    status: value.index
                };
                that.$api.apply.rollback(info).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.picker.rollback = false;
                        that.order.master.status = value.index;
                        that.order.master.statusName = value.text;
                    } else {
                        that.$toast(res.msg);
                    }
                })

            },
            // 修改订单
            onEdit() {
                this.picker.edit = true;
            },
            // 修改完成
            onEditComplete() {
                this.picker.edit = false;
                this.init();
            },
            // 删除订单
            onDelete() {
                let that = this;
                if (that.order.master.status == 1) {
                    that.$dialog.confirm({
                        message: '操作不可逆，确认删除吗？'
                    }).then(() => {
                        that.$api.apply.delete({id: that.param.id}).then(response => {
                            let res = response.data;
                            if (res.code == 1) {
                                that.$toast.success(res.msg);
                                that.$emit('onClose');
                            } else {
                                that.$toast.fail(res.msg);
                            }
                        });
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .apply-detail-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .apply-detail-container-content {
        position: fixed;
        top: 46px;
        bottom: 0;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .apply-detail-container-btn {
        text-align: center;
        background-color: #fff;
        padding: 10px 16px;
    }

    .van-cell__value {
        flex: 4
    }

    .apply-goods {
        text-align: center;
        border-bottom: 1px solid #ebedf0;
        color: #323232;
        line-height: 30px;
        font-size: 10px;
    }

    .apply-goods-title {
        background: #f5f5f5;
    }

    .apply-goods-item {
        border-bottom: 1px dashed #ebedf0;
    }

    .apply-goods-item:last-child {
        border: none;
    }

    .no-bottom {
        bottom: 0;
    }

</style>
